export const DIMENSION = {
  Desktop: 'desktop',
  Tablet: 'tablet',
  Mobile: 'mobile',
};

export const LAYOUT = {
  Fluid: 'fluid',
  Boxed: 'boxed',
};

export const RADIUS = {
  Rounded: 'rounded',
  Standard: 'standard',
  Flat: 'flat',
};

export const THEME_COLOR = {
  LightBlue: 'light-blue',
  DarkBlue: 'dark-blue',
  LightRed: 'light-red',
  DarkRed: 'dark-red',
  LightGreen: 'light-green',
  DarkGreen: 'dark-green',
  LightPurple: 'light-purple',
  DarkPurple: 'dark-purple',
  LightPink: 'light-pink',
  DarkPink: 'dark-pink',
};

export const NAV_COLOR = {
  Default: 'default',
  Light: 'light',
  Dark: 'dark',
};
export const MENU_PLACEMENT = {
  Vertical: 'vertical',
  Horizontal: 'horizontal',
};
export const MENU_BEHAVIOUR = {
  Pinned: 'pinned',
  Unpinned: 'unpinned',
};

export const USER_ROLE = {
  Admin: 'A',
  Editor: 'U',
};

/* 
export const catImageLivePath ='http://localhost:5000/itemcatimg/';
export const itemImageLivePath ='http://localhost:5000/itemimg/'; 
export const eventFilePath  = 'http://localhost:5000/eventsimg/';
export const magazineFilePath = 'http://localhost:5000/magazine/';

*/
export const maxBarItemPerCart = 2;

export const catImageLivePath ='https://webapistaging.madrascricketclub.org/itemcatimg/';
export const itemImageLivePath ='https://webapistaging.madrascricketclub.org/itemimg/'; 
export const eventFilePath  = 'https://webapistaging.madrascricketclub.org/eventsimg/';
export const magazineFilePath = 'https://webapistaging.madrascricketclub.org/magazine/';
export const broadcastPath  = 'https://webapistaging.madrascricketclub.org/broadcast/';
export const chamberFilePath = 'https://webapistaging.madrascricketclub.org/chamber/';

export const imageupload  = 'https://webapistaging.madrascricketclub.org/imageupload/';