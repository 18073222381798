const enMessages = {
  'menu.ManageFeedBacks':'Manage FeedBacks',
  'menu.myFeedBacks': 'My Feedbacks',
  'menu.feedbacks':'Feedbacks',
  'menu.clubman':'Club Man',
  'menu.ManageClubmans': 'Manage Clubman',
  'menu.ManageEventsOrder':'Manage Events Orders',
  'menu.dashboards': 'Dashboards',
  'menu.currentIssue': 'Current Issue',
  'menu.archive': 'Archive',
  'Library':'Library',
  'Member Dashboard':'Member Dashboard',
  'F&B':'F&B',
  'F&B Food Items':'Food Menu', 
  'F&B Orders':'Food Orders',
  'F&B Bar Menu': 'Bar Menu',
  'F&B Bar Orders':'Bar Orders',
  'The Chambers':'The Chambers',
  'Book Chambers':'Book Chambers',
  'My Chamber Bookings': 'My Bookings',
  'Banquets - Coming soon':'Banquets - Coming soon',
  'Events - Coming soon':'Events - Coming soon',
  'Other Club Events':'Other Club Events',
  'Notice Board - Coming soon':'Notice Board - Coming soon',
  'Clubman':'Clubman',
  'Events': 'Events',
  'My Events Booking':'My Events Booking',
  'Events List':'Events List',
  'Feedback - Coming soon':'Feedback - Coming soon',
  'Facilities Timings':'Facilities Timings',
  'Change Password':'Change Password',
  'menu.default': 'Default',
  'menu.visual': 'Visual',
  'menu.analytic': 'Analytic',
  'menu.authentication': 'Authentication',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.forgot-password': 'Forgot Password',
  'menu.reset-password': 'Reset Password',
  'menu.home': 'Home',
  'menu.detail': 'Detail',
  'menu.faq': 'Faq',
  'menu.knowledge-base': 'Knowledge Base',
  'menu.error': 'Error',
  'menu.search': 'Search',
  'menu.empty': 'Empty',
  'menu.profile': 'Profile',
  'menu.standard': 'Standard',
  'menu.settings': 'Settings',
  'menu.alerts': 'Alerts',
  'menu.breadcrumb': 'Breadcrumb',
  'menu.autocomplete': 'Autocomplete',
  'menu.notification': 'Notification',
  'menu.menu': 'Menu',
  'menu.account': 'Account',
  'menu.password': 'Password',
  'menu.notifications': 'Notifications',
  'menu.email': 'Email',
  'menu.sms': 'SMS',
  'menu.downloads': 'Downloads',
  'menu.documents': 'Documents',
  'menu.videos': 'Videos',
  'menu.dashboard': 'Dashboard',
  'menu.products': 'Products',
  'menu.orders': 'Orders',
  'menu.customers': 'Customers',
  'menu.storefront': 'Storefront',
  'menu.filters': 'Filters',
  'menu.categories': 'Categories',
  'menu.cart': 'Cart',
  'menu.checkout': 'Checkout',
  'menu.invoice': 'Invoice',
  'menu.shipping': 'Shipping',
  'menu.discount': 'Discount',
  'menu.general': 'General',
  'menu.member':'Members',
  'menu.ManageMember':'Manage Members',
  'menu.addMember':'Add Member',
 
  'menu.restaurantMaster': 'Restaurant Masters',
  'menu.ManageCategory':'Manage Categories',
  'menu.addCategory': 'Add Category',
  'menu.ManageFoodItem': 'Manage Food Items',
  'menu.addFoodItem':'Add Food Item',
  'menu.ManageFoodOrder':'Manage Food Orders',

  'menu.BarManageCategory': 'Manage Bar Category',
  'menu.BarManageFoodItem':'Manage Bar Items',
  'menu.BarManageFoodOrder': 'Manage Bar Orders',
  'menu.Chamber':'Chambers',
  'menu.ManageOrders':'Manage Bookings',
  'menu.reservationRequest': 'Reservation Requests',

  'menu.events' :'Events',
  'menu.ManageEvents':'Manage Events',
  'menu.addEvents': 'Add Events',

  'menu.report': 'Report',
  'menu.paymentreport': 'Payment Report',
  'menu.eventreport': 'Event Booking Report',
  'menu.broadcast':'Broadcast',
  'menu.ManageBroadcast':'Manage Broadcast',

  'menu.Banquet': 'Banquet Masters',
  'menu.ManageBanquets': 'Manage Banquets',
  'menu.addBanquet': 'Add  Banquet',
  'menu.ManageBanquetMenu': 'Manage Banquet Menu',
  'menu.ManageBanquetMenuItem': 'Manage Banquet Menu Item',
  'menu.addBanquetMenu': 'Add Banquet Menu',
  'menu.ManageBanquetOrder': 'Manage Banquets Booking',

  'menu.setting':'Setting',
  'menu.committeemember': 'Committee Member',
  'menu.affiliateclub': 'Affiliate Club',
  'menu.imageupload': 'Image Upload',

};
export default enMessages;
